import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { memo } from 'react';
import KeyValuePairFields from './KeyValuePairFields';

/**
 *
 * @param {FormKeyValuePair[]} currentListOfKeyValuePairs
 * @param {string} updateType
 * @param {string|FormKeyValueDefinition} value
 * @param {number} index
 * @returns {FormKeyValuePair[]}
 */
export const handleKeyValuePairUpdate = (currentListOfKeyValuePairs, updateType, value, index) => {
    const activeKeyValueList = [...currentListOfKeyValuePairs];
    const currentKeyValuePair = activeKeyValueList?.[index] || { key: '', value: { rawData: '' } };

    switch (updateType) {
        case 'key':
            currentKeyValuePair[updateType] = value;
            break;
        case 'value':
            if (typeof value === 'string') {
                currentKeyValuePair[updateType] = { rawData: value };
            } else {
                currentKeyValuePair[updateType] = value;
            }
            break;
    }

    activeKeyValueList[index] = currentKeyValuePair;

    return activeKeyValueList;
};

/**
 *
 * @param {KeyValuePairFieldSet[]} currentListOfKeyValuePairs
 * @param {number} removeIndex
 */
export const handleKeyValuePairRemove = (currentListOfKeyValuePairs, removeIndex) => {
    const outputArray = [];
    for (let currentIndex = 0; currentIndex < currentListOfKeyValuePairs.length; currentIndex++) {
        if (currentIndex !== removeIndex) {
            outputArray.push(currentListOfKeyValuePairs[currentIndex]);
        }
    }

    return outputArray;
};

/**
 * @param {FormKeyValueDefinition} value
 * @returns {boolean}
 */
const isValidValueForPair = (value) => {
    if (typeof value === 'object') {
        return (
            (value?.rawData && value.rawData !== '') ||
            (value?.sourceStepId &&
                value?.sourceDataTypeId &&
                value?.sourceDisplayLabel &&
                Array.isArray(value?.sourceInputPath))
        );
    }

    return false;
};

/**
 * Checks an array of key value pairs for
 * @param {FormKeyValuePair[]} listOfKeyValuePairs
 * @param {boolean} requireAtLeastOneEntry
 * @returns {boolean}
 */
export const isValidKeyValuePairList = (listOfKeyValuePairs, requireAtLeastOneEntry = false) => {
    let hasInvalidPair = false;
    if (listOfKeyValuePairs.length > 0) {
        for (const pair of listOfKeyValuePairs) {
            if (!pair?.key || pair?.key === '' || !isValidValueForPair(pair?.value || {})) {
                hasInvalidPair = true;
            }
        }
    } else if (requireAtLeastOneEntry) {
        return false;
    }

    return !hasInvalidPair;
};

/**
 * @typedef FormKeyValueDefinition
 * @property {string?} rawData
 * @property {number?} sourceStepId
 * @property {string?} sourceDataTypeId
 * @property {string[]?} sourceInputPath
 * @property {string?} sourceDisplayLabel
 * @property {Object[]?} sourceInputFilters
 * @property {number?} sourceInputEntryOffset
 */

/**
 * @typedef FormKeyValuePairKeyValueSelectOption
 * @property {string} key
 * @property {false|VersionType} versionedItemType
 * @property {FormKeyValueValidSelectOption[]|VersionedDocument[]} selectOptions
 */

/**
 * @typedef FormKeyValuePairOptions
 * @property {FormKeyValuePairKeyValueSelectOption[]} keysRequiringValueSelect
 */

/**
 * @typedef FormKeyValuePair
 * @property {string} key
 * @property {FormKeyValueDefinition} value
 * @property {FormKeyValuePairOptions} options
 */

/**
 * @typedef FormKeyValueValidSelectOption
 * @property {string} label
 * @property {string} value
 */

/**
 * @callback HandleAdditionalFieldProps
 * @param {string} type
 * @param {number} index
 * @param {FormKeyValuePair} currentKeyValue
 * @param {boolean} isValueSelect
 * @returns {void}
 */

/**
 * @callback HandleRemoveIndex
 * @param {number} index
 * @returns {void}
 */

/**
 *
 * @param {IntegrationBuilderProps} integrationBuilderProps
 * @param {FormKeyValuePair[]} listOfKeyValuePairs
 * @param {HandleAdditionalFieldProps} handleAdditionalFieldProps
 * @param {HandleRemoveIndex} handleRemoveIndex
 * @param {boolean} isSubmittingForm
 * @param {FormKeyValueValidSelectOption[]} listOfValidKeys
 * @param {boolean} excludeUsedKeys
 * @param {FormKeyValuePairOptions} options
 */
const KeyValuePairFieldSet = ({
    integrationBuilderProps,
    listOfKeyValuePairs,
    handleAdditionalFieldProps,
    handleRemoveIndex,
    isSubmittingForm,
    listOfValidKeys,
    excludeUsedKeys = true,
    options = {},
}) => {
    const { isIntegrationEditable } = integrationBuilderProps;
    const isKeyUsingSelect = Array.isArray(listOfValidKeys) && listOfValidKeys.length > 0;
    const selectedKeys = listOfKeyValuePairs.map((item) => item.key);

    const fieldsToRender = [...listOfKeyValuePairs, { key: '', value: {} }].map((keyValue, index, sourceArray) => {
        const isLastEntry = index + 1 === sourceArray.length;
        const isDisabled = !isIntegrationEditable;

        return (
            <div
                key={index}
                style={{ display: 'flex', justifyItems: 'stretch', alignItems: 'center', padding: '5px 0' }}
            >
                <KeyValuePairFields
                    handleAdditionalFieldProps={handleAdditionalFieldProps}
                    index={index}
                    keyValue={keyValue}
                    options={options}
                    excludeUsedKeys={excludeUsedKeys}
                    isIntegrationEditable={isIntegrationEditable}
                    isKeyUsingSelect={isKeyUsingSelect}
                    listOfValidKeys={listOfValidKeys}
                    selectedKeys={selectedKeys}
                    sourceArray={sourceArray}
                    isSubmittingForm={isSubmittingForm}
                />
                <div>
                    <Button
                        style={{
                            height: '3em',
                            background: '#3a70d4',
                            borderRadius: '10px',
                            width: '50px',
                            border: '1px solid #3a70d4',
                            visibility: isLastEntry ? 'hidden' : 'visible',
                        }}
                        onClick={() => {
                            handleRemoveIndex(index);
                        }}
                        disabled={isDisabled}
                    >
                        <Typography style={{ fontSize: '2em', cursor: 'pointer', color: 'white' }}>-</Typography>
                    </Button>
                </div>
            </div>
        );
    });

    return <div>{fieldsToRender}</div>;
};

KeyValuePairFieldSet.propTypes = {
    integrationBuilderProps: PropTypes.object.isRequired,
    listOfKeyValuePairs: PropTypes.array.isRequired,
    handleRemoveIndex: PropTypes.func.isRequired,
    handleAdditionalFieldProps: PropTypes.func.isRequired,
    isSubmittingForm: PropTypes.bool.isRequired,
    listOfValidKeys: PropTypes.array,
    excludeUsedKeys: PropTypes.bool,
    options: PropTypes.object,
};

export default memo(KeyValuePairFieldSet);
