import SelectField from '../../SelectField';
import TextField from '../../TextField';
import VersionedDocumentSelector from '../../../VersionedDocumentSelector';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';

export default function KeyValuePairFields({
    keyValue,
    options,
    index,
    sourceArray,
    isIntegrationEditable = true,
    isKeyUsingSelect,
    listOfValidKeys,
    selectedKeys,
    excludeUsedKeys,
    handleAdditionalFieldProps,
    isSubmittingForm,
}) {
    const valueDisplayText = keyValue.value?.sourceDisplayLabel || keyValue.value?.rawData || '';
    const keyValueOption =
        options?.keysRequiringValueSelect?.find((keyValueOption) => keyValueOption.key === keyValue.key) || null;
    const isKeyValueUsingSelect = keyValueOption !== null;
    const isLastEntry = index + 1 === sourceArray.length;
    const isDisabled = !isIntegrationEditable;
    const availableKeys = [];
    const [valueInputJsx, setValueInputJsx] = useState(<></>);

    let hasError = false;
    if (!isLastEntry && sourceArray.length > 1) {
        if (!keyValue.value?.sourceDataTypeId && !keyValue.value?.rawData) {
            hasError = true;
        }
    }

    if (isKeyUsingSelect) {
        for (const availableKey of listOfValidKeys) {
            let isValid = true;

            for (const usedKey of selectedKeys) {
                if (availableKey.value === usedKey && keyValue.key !== usedKey) {
                    isValid = false;
                }
            }

            if (isValid || !excludeUsedKeys) {
                availableKeys.push(availableKey);
            }
        }
    }

    useEffect(() => {
        /**
         * @returns {JSX.Element}
         */
        const getPairValueTextField = () => {
            return (
                <TextField
                    placeholder="Value"
                    label={null}
                    required={index + 1 !== sourceArray.length}
                    disabled={isDisabled}
                    value={valueDisplayText}
                    onChange={() => {}}
                    isSubmittingForm={isSubmittingForm}
                    renderWithGrid={false}
                    error={hasError}
                    showError={false}
                    sx={{ margin: '0' }}
                    inputProps={handleAdditionalFieldProps('value', index, keyValue, false)}
                />
            );
        };

        /**
         * @returns {JSX.Element}
         */
        const getPairValueSelectBox = () => {
            const selectValue = keyValueOption.selectOptions.length > 0 ? keyValue?.value?.rawData || '' : '';
            if (keyValueOption) {
                if (typeof keyValueOption.versionedItemType === 'string') {
                    return (
                        <VersionedDocumentSelector
                            showLabel={false}
                            versionType={keyValueOption.versionedItemType}
                            versionedItems={keyValueOption.selectOptions}
                            selectedItemId={selectValue}
                            allowClear={false}
                            styleOverrides={{ containerStyles: { margin: '0 auto', width: '100%' } }}
                            {...handleAdditionalFieldProps('value', index, keyValue, true)}
                        />
                    );
                } else {
                    return (
                        <SelectField
                            name={`step-parameter-value-${index}`}
                            label={null}
                            inputDataList={keyValueOption.selectOptions}
                            getItemLabel={(item) => item?.label}
                            getItemValue={(item) => item?.value}
                            renderWithGrid={false}
                            required={index + 1 !== sourceArray.length}
                            value={selectValue}
                            showError={false}
                            error={hasError}
                            sx={{
                                marginTop: isLastEntry ? '4px' : '0',
                            }}
                            inputProps={handleAdditionalFieldProps('value', index, keyValue, true)}
                            disabled={isDisabled}
                        />
                    );
                }
            }

            return <></>;
        };

        setValueInputJsx(isKeyValueUsingSelect ? getPairValueSelectBox() : getPairValueTextField());
    }, [keyValue, isKeyValueUsingSelect]);

    /**
     * @returns {JSX.Element}
     */
    const getPairKeySelectBox = () => {
        return (
            <SelectField
                name={`step-parameter-${index}`}
                label={null}
                inputDataList={availableKeys}
                getItemLabel={(item) => item?.label}
                getItemValue={(item) => item?.value}
                renderWithGrid={false}
                value={availableKeys.length > 0 ? keyValue?.key || '' : ''}
                showError={false}
                error={hasError}
                sx={{ marginTop: isLastEntry ? '4px' : '0' }}
                inputProps={handleAdditionalFieldProps('key', index, keyValue, true)}
                disabled={isDisabled}
            />
        );
    };

    /**
     * @returns {JSX.Element}
     */
    const getPairKeyTextField = () => {
        return (
            <TextField
                placeholder="Key"
                label={null}
                required={index + 1 !== sourceArray.length}
                disabled={isDisabled}
                value={keyValue.key}
                onChange={() => {}}
                isSubmittingForm={isSubmittingForm}
                renderWithGrid={false}
                error={hasError}
                showError={false}
                sx={{ margin: '0' }}
                inputProps={handleAdditionalFieldProps('key', index, keyValue, false)}
            />
        );
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                margin: 0,
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: '10px',
                columnGap: '10px',
            }}
        >
            <div style={{ margin: '0', width: '50%' }}>
                {isKeyUsingSelect ? getPairKeySelectBox() : getPairKeyTextField()}
            </div>
            <div style={{ margin: '0', width: '175px' }}>{valueInputJsx}</div>
        </div>
    );
}

KeyValuePairFields.propTypes = {
    keyValue: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    sourceArray: PropTypes.array,
    isIntegrationEditable: PropTypes.bool,
    isKeyUsingSelect: PropTypes.bool,
    listOfValidKeys: PropTypes.array,
    selectedKeys: PropTypes.array,
    excludeUsedKeys: PropTypes.bool,
    isSubmittingForm: PropTypes.bool,
    handleAdditionalFieldProps: PropTypes.func.isRequired,
};
