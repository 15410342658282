import { authorizedAxiosInstance } from '../axiosInstances';
import { Cookies } from 'react-cookie';
import { createMemoryHistory } from 'history';
import store from '../redux/store';
import { clarity } from 'react-microsoft-clarity';

import * as constants from './constants';

import HttpStatusCodes from '../classes/HttpStatusCodes';

import {
    getLocalStorageCacheItem,
    setLocalStorageCacheItem,
    destroyLocalStorageCache,
    removeLocalStorageCacheItem,
} from '../hooks/useLocalStorageHook';

import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
    showSnackBarSessionIdleTimeNotification,
} from './snackBarNotificationUtil';

import * as envUtils from '../utils/envUtils';
import { isIntelyOrganizationId } from './organizationUtils';
import { APPLICATION_ID_CONNECT } from './constants';
import { getGatewayUrl, logError } from './envUtils';

const cookies = new Cookies();
let sessionCheckInterval;

export const AUTH_USER_SESSION_TOKEN_ACTIONS = {
    sessionPopupLastDisplayTime: 'sessionPopupLastDisplayTime',
};

const sessionPopupLastDisplayTimeActionType = AUTH_USER_SESSION_TOKEN_ACTIONS.sessionPopupLastDisplayTime;

/**
 * Process the request response object
 * @param {Object} response
 * @param {Function|null} callback Success callback
 * @returns {any}
 */
const processRequestResponse = (response, callback = null) => {
    if (response?.status === HttpStatusCodes.SUCCESS) {
        if (callback) {
            callback(response.data);
        }

        return response.data;
    } else {
        throw response.errors;
    }
};

/**
 * Validate the authenticated user session token
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserLogin = async () => {
    return authorizedAxiosInstance
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/currentUser/validate`)
        .then(processRequestResponse)
        .catch((error) => {
            envUtils.logError(error);
            return false;
        });
};

/**
 * Get user data from back-end.
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserData = async (userId) => {
    if (hasUserLoggedIn()) {
        return authorizedAxiosInstance
            .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/user/${userId}`)
            .then(processRequestResponse)
            .catch((error) => {
                throw error;
            });
    }
};

/**
 *
 * @returns {boolean}
 */
export const hasUserLoggedIn = () => {
    return cookies.get('hasLoggedIn') === 'true';
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationData = async (organizationId) => {
    return authorizedAxiosInstance
        .get(`${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/organization/${organizationId}`)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

export const redirectUserTokenExpired = () => {
    logout('session.expired');
};

/**
 * Check whether the current user session
 *
 * Note: Token refresh is triggered when the auth user session
 * expiration time left is just 1 minute.
 *
 * @returns {Promise<boolean>}
 */
export const isCurrentUserLoginValid = async () => {
    const userData = await getCurrentUserData();

    // Monitor the session token expiration and alert the user if necessary
    handleSessionTokenRefreshUsingPopup(userData);

    // Set user id in clarity tracking
    if (userData?.userId && clarity.hasStarted()) {
        clarity.identify(userData.userId, {
            email: userData?.email,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            organizationId: userData?.defaultOrganizationId,
        });
    }

    return userData && userData.userId;
};

/**
 * @returns {Promise<Object|false>}
 */
export const getUpcomingMaintenanceMode = async () => {
    try {
        const maintenanceWindowResponse = await authorizedAxiosInstance.request({
            method: 'get',
            url: getGatewayUrl('/noauth/maintenanceWindows/upcoming'),
        });

        if (maintenanceWindowResponse.status === HttpStatusCodes.SUCCESS) {
            return maintenanceWindowResponse.data;
        }
    } catch (error) {
        logError(error);
    }

    return false;
};

/**
 * @returns {Promise<boolean>}
 */
export const isMaintenanceModeEnabled = () => {
    return getUpcomingMaintenanceMode()
        .then((maintenanceModeObject) => {
            if (maintenanceModeObject !== false) {
                return maintenanceModeObject.isActive && maintenanceModeObject.disablePlatformLogins;
            }

            return false;
        })
        .catch(() => {
            return false;
        });
};

/**
 * Get the authenticated user data.
 * @returns {Promise<AxiosResponse<*> | boolean>}
 */
export const getCurrentUserData = async () => {
    return getUserLogin()
        .then(async (data) => {
            return data;
        })
        .catch(() => {
            return false;
        });
};

/**
 * Get current authenticated user organization ID
 * @returns {String}
 */
export const getUserCurrentOrganizationId = (userData) =>
    Array.isArray(userData?.organizations) && userData.organizations?.length ? userData.organizations[0] : '';

/**
 * Get current authenticated user organization ID
 */
export const getUserCurrentOrganizationData = async () => {
    let organizationData = { stripeCustomerId: '' };

    try {
        const organizationId = getCurrentSelectedOrganizationId();
        if (organizationId) {
            organizationData = await getOrganizationData(organizationId);
        }
    } catch (error) {
        // TODO log exception
    }

    return organizationData;
};

/**
 * Update the user store data with the current authenticated user
 * @param {Object} data
 * @returns {Promise<void>}
 */
export const updateCurrentUserData = async (data) => {
    await setupAuthUserDataWithStore(store, data);
    // TODO need to setup organization data in global store or read from platform storage
};

/**
 * Setup the authenticated user data to the redux store.
 * @param {Object} reduxStore Redux store
 * @param {Object} data User data
 * @returns {Promise<void>}
 */
export const setupAuthUserDataWithStore = async (reduxStore, data) => {
    try {
        const currentOrganizationId = getUserCurrentOrganizationId(data),
            organizationData = await getUserCurrentOrganizationData();
        let hasConnectAccess = false;

        if (Array.isArray(organizationData?.applications)) {
            hasConnectAccess =
                organizationData.applications.filter((element) => element?.applicationId === APPLICATION_ID_CONNECT)
                    .length > 0;
        }

        if (hasConnectAccess) {
            reduxStore.dispatch({
                type: 'UPDATE_USERDATA',
                payload: {
                    data: {
                        id: data.userId,
                        has_payment: false,
                        email: data.email,
                        stripeId: organizationData?.stripeId,
                        firstname: data.firstName,
                        lastname: data.lastName,
                        organizations: currentOrganizationId,
                        currentOrganizationId: currentOrganizationId,
                        sessionInitiatedAt: data.iat,
                        sessionExpiredAt: data.exp,
                        defaultOrganizationId: data.defaultOrganizationId,
                        hasConnectAccess: hasConnectAccess,
                    },
                },
            });
        } else {
            logout('session.forbidden');
        }
    } catch (e) {
        // TODO: handle error
    }
};

/**
 * Set up a session timeout validation interval which will monitor
 * for a valid user session.
 *
 * @returns {void}
 */
export const setupSessionTimeoutValidation = () => {
    if (sessionCheckInterval) {
        clearInterval(sessionCheckInterval);
    }

    sessionCheckInterval = setInterval(validateSessionTimeout, constants.SESSION_TIMEOUT_CHECK_INTERVAL);
};

/**
 * Validate user session. If the session is invalid/expired,
 * the session is wiped out and user is redirected to platform
 * login page.
 *
 * @returns {Promise<boolean>}
 */
export const validateSessionTimeout = async () => {
    if ((await isCurrentUserLoginValid()) && !(await isMaintenanceModeEnabled())) {
        return true;
    } else {
        clearInterval(sessionCheckInterval);
        redirectUserTokenExpired();
        return false;
    }
};

/**
 * Wipe out the user session and go to the platform login page.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.forbidden': User does not have access to connect application
 * - 'session.application.error': Application error occurred during login
 * process
 */
export const logout = (reason = '') => {
    let logoutUrl = `${process.env.REACT_APP_INTELY_PLATFORM_EXTERNAL_HOST}/logout`;
    handleLogoutReason(reason);
    destroyLocalStorageCache(maybePreserveLocalStatesByReason(reason));
    window.onbeforeunload = false;

    switch (reason) {
        case 'session.forbidden':
            logoutUrl += '?forbidden=true';
            break;
    }

    // Stop Clarity tracking
    if (clarity.hasStarted()) {
        clarity.stop();
    }

    window.location.replace(logoutUrl);
};

/**
 * Refresh the authenticated user session token
 *
 * @param {Object} notification Specify whether to show a notification to the user based on the response.
 * @param {Boolean} [notification.error] Whether to show error notification if request failed
 * @param {Boolean} [notification.success] Whether to show success notification if request was successful.
 *
 * @return {Promise<object|void>}
 */
export const refreshUserToken = async (
    notification = {
        error: true,
        success: true,
    },
) => {
    return authorizedAxiosInstance
        .post(
            `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}/user/${getCurrentUserId()}/refreshToken`,
            {}, // data
        )
        .then((response) => {
            return processRequestResponse(response, async () => {
                //console.log(`refreshing user token`);
                const responseTokenPayload = response?.data?.tokenPayload;

                await updateCurrentUserData(responseTokenPayload); // TODO more efficient way?
                updateUserLastRefreshTime();
                updateUserLastActivityTime();
                setSessionPopupLastDisplayTime(0);

                if (notification?.success) {
                    showSnackBarSuccessNotification('Your session has been refreshed successfully.');
                }
            });
        })
        .catch(() => {
            if (notification?.error) {
                showSnackBarErrorNotification('An error occurred, unable to refresh your session token.');
            }
        });
};

/**
 * Display a popup to the user which allows them to refresh their current session which will time out due to inactivity.
 * @param {Object} userData
 * @see isCurrentUserLoginValid()
 */
export const handleSessionTokenRefreshUsingPopup = (userData) => {
    const hasUserData = userData && userData.userId,
        tokenExpiration = parseInt(userData?.exp) * 1000;

    if (hasUserData && !isNaN(tokenExpiration)) {
        const timeUntilTokenExpiration = tokenExpiration - Date.now(),
            sessionPopupLastDisplayTime = getSessionPopupLastDisplayTime();

        // Purposefully left these useful debug statements in for future diagnosis
        // console.log('background: token expiration:', tokenExpiration);
        // console.log('background: token expires in minutes:', timeUntilTokenExpiration / 60000);
        // console.log('background: idle popup shown:', isSessionIdlePopupShown());
        // console.log('background: sessionPopupLastDisplayTime', sessionPopupLastDisplayTime);

        if (
            timeUntilTokenExpiration > 0 &&
            timeUntilTokenExpiration <= constants.SESSION_TOKEN_REFRESH_DISPLAY_POPUP_THRESHOLD &&
            !isSessionIdlePopupShown()
        ) {
            // Show session popup in the next 2 minutes after
            // the last display if user mistakenly closes the snackbar popup

            if (sessionPopupLastDisplayTime === 0 || (Date.now() - sessionPopupLastDisplayTime) / 1000 >= 120) {
                showSnackBarSessionIdleTimeNotification();
            }
        }
    }
};

/**
 * Process the logout reason. This is used provide context to
 * which the user is logged out.
 * Especially useful for doing a redirect to url.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.application.error': Application error occurred during login process
 */
const handleLogoutReason = (reason) => {
    if (!reason) return;

    if (reason === 'session.expired') {
        const currentPageUrl = getCurrentPagePath();

        setLocalStorageCacheItem(
            'session.states',
            {
                reason,
                redirectTo: currentPageUrl !== '/' ? currentPageUrl : '',
            },
            true,
        );
    }
};

/**
 * Get the current page path.
 * @returns {String}
 */
export const getCurrentPagePath = () => window.location.pathname + window.location.search + window.location.hash;

/**
 * Check whether we can restore user session states after log in.
 * @see handleLogoutReason()
 */
export const restoreUserSessionStates = () => {
    const sessionStates = getLocalStorageCacheItem('session.states');
    if (!sessionStates) return;

    const { redirectTo = '' } = sessionStates;

    if (redirectTo && redirectTo !== '/') {
        removeLocalStorageCacheItem('session.states');

        const history = createMemoryHistory();
        history.push(redirectTo);
    }
};

/**
 * Check whether we can preserve local states based on the reason
 * the user is logged out.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.application.error': Application error occurred during login
 * process
 *
 * @returns {Array<string>} An array of reasons that specifies local states to
 * be preserved and restored once the user is logged-in back into the
 * application.
 */
const maybePreserveLocalStatesByReason = (reason) => {
    const preservableLocalStates = [];

    if (reason === 'session.states') {
        preservableLocalStates.push(reason);
    }

    return preservableLocalStates;
};

/**
 * Check whether the session idle time popup is shown
 * @returns {Boolean}
 */
export const isSessionIdlePopupShown = () => store.getState().feedback?.type === 'sessionIdleTimeCheck';

/**
 * Get the last activity time of the user
 * @returns {number} Timestamp of when the last user activity occurred
 */
export const getUserLastActivityTime = () => getLocalStorageCacheItem('lastActivityTime', 0);

/**
 * Update the last activity time of the user
 */
export const updateUserLastActivityTime = () => {
    setLocalStorageCacheItem('lastActivityTime', Date.now());
};

// UNUSED - DELETE?
/**
 *
 * @returns {Number}
 */
export const getUserLastRefreshTime = () => getLocalStorageCacheItem('userLastRefreshTime', 0);

/**
 * Update the last time user data was refreshed from token payload.
 */
export const updateUserLastRefreshTime = () => {
    setLocalStorageCacheItem('userLastRefreshTime', Date.now());
};

/**
 * Set the session popup last display time.
 * @param {Number} timestamp
 */
export const setSessionPopupLastDisplayTime = (timestamp) => {
    store.dispatch({
        type: sessionPopupLastDisplayTimeActionType,
        payload: timestamp,
    });
};

/**
 * Get the session popup last display time.
 * @returns {Number}
 */
export const getSessionPopupLastDisplayTime = () =>
    store.getState().authUserSessionToken?.[sessionPopupLastDisplayTimeActionType];

/**
 *
 * @returns {String}
 */
export const getCurrentUserId = () => {
    return store.getState().userdata.data.id;
};

/**
 *
 * @returns {String}
 */
export const getCurrentUserEmail = () => {
    return store.getState().userdata?.data.email;
};

/**
 * @returns {String}
 */
export const getCurrentOrganizationId = () => {
    return store.getState().currentOrganization.organizationId;
};

/**
 * @returns {string}
 */
export const getDefaultOrganizationIdUrlParameter = () => {
    const urlParameters = getUrlParameters();
    return urlParameters.get('currentOrganizationId');
};

/**
 * @returns {String}
 */
export const getCurrentSelectedOrganizationId = () => {
    return localStorage.getItem('currentOrganizationId');
};

/**
 * Check whether intely organization is currently selected
 * @returns {Bool}
 */
export const isIntelyOrganizationCurrentlySelected = () => isIntelyOrganizationId(getCurrentSelectedOrganizationId());

/**
 *
 * @returns {module:url.URLSearchParams}
 */
export const getUrlParameters = () => {
    return new URLSearchParams(window.location.search);
};
