import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MenuList } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { getVersionedDocumentById, getVersionsByDocumentId } from '../../utils/versionUtils';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../utils/constants';
import InputLabel from '@mui/material/InputLabel';
import styles from '../IntegrationBuilder/IntegrationBuilderStepDrawer/IntegrationBuilderStepDrawerFields/IntegrationBuilderStepCrosswalkField/styles';
import ListSubheader from '@mui/material/ListSubheader';
import { showSnackBarErrorNotification } from '../../utils/snackBarNotificationUtil';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { logError } from '../../utils/envUtils';

const MENU_ITEM_SELECT = 1;
const MENU_ITEM_VERSION_SELECT = 2;

/**
 * @param {VersionType} versionType
 * @param {VersionedDocument} item
 */
const getDisplayNameForVersionType = (versionType, item) => {
    let output = '';
    switch (versionType) {
        case 'dataType':
            if (item?.category === 'HL7 v2.x') {
                output = `${item?.displayName} (${item?.name})`;
            } else {
                output = item?.displayName || item?.name || '';
            }
            break;
        default:
            output = item?.name || '';
            break;
    }

    return output.trim();
};

/**
 * @param {VersionType} versionType
 */
const sortItems = (versionType) => {
    return (item1, item2) => {
        const item1Name = getDisplayNameForVersionType(versionType, item1).toLowerCase();
        const item2Name = getDisplayNameForVersionType(versionType, item2).toLowerCase();

        if (item1Name === item2Name) {
            return 0;
        }

        if (item1Name > item2Name) {
            return 1;
        }

        return -1;
    };
};

/**
 * @typedef VersionedDocumentSelectorStyleOverrides
 * @property {Object?} labelStyles
 * @property {Object?} containerStyles
 */

/**
 * @typedef VersionedDocumentSelectorProps
 * @property {VersionType} versionType
 * @property {VersionedDocument|null} selectedItem
 * @property {VersionedDocument[]} versionedItems
 * @property {(selectedItem: VersionedDocument) => Promise<void>|void} handleChange
 * @property {boolean?} isRequired
 * @property {boolean?} hasError
 * @property {boolean?} isDisabled
 * @property {boolean?} allowClear
 * @property {boolean|string?} showLabel
 * @property {string?} placeholder
 * @property {number?} sortType
 * @property {function?} sortFunction
 * @property {function?} additionalFilterFunction
 * @property {VersionedDocumentSelectorStyleOverrides?} styleOverrides
 * @property {boolean?} hideVersionInformationInSelect
 */

/**
 * @param {VersionedDocumentSelectorProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const VersionedDocumentSelector = ({
    versionType,
    versionedItems,
    selectedItem,
    handleChange,
    isRequired,
    hasError,
    isDisabled,
    showLabel,
    placeholder,
    sortFunction,
    styleOverrides,
    allowClear = true,
    overrideLabel = false,
    additionalFilterFunction = null,
    selectedItemId = null,
    hideVersionInformationInSelect = false,
}) => {
    const [documentIdVersions, setDocumentIdVersions] = useState([]);
    const [currentMainItemSelected, setCurrentMainItemSelected] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isLoadingVersions, setLoadingVersions] = useState(false);
    const [currentMenuDisplayed, setCurrentMenuDisplayed] = useState(MENU_ITEM_SELECT);
    const [documentFilterText, setDocumentFilterText] = useState('');
    const [versionFilterText, setVersionFilterText] = useState('');
    const versionStateHeaderStyle = { fontWeight: 'bold', padding: '0px 10px', margin: '0', lineHeight: '32px' };
    const sortFunctionToUse = sortFunction || sortItems(versionType);
    const filteredVersionedItems = versionedItems
        .filter(
            (item) =>
                getDisplayNameForVersionType(versionType, item)
                    .toLowerCase()
                    .includes(documentFilterText.toLowerCase()) || documentFilterText === '',
        )
        .sort(sortFunctionToUse);
    const menuStyle = { maxHeight: '750px' };
    if (typeof showLabel === 'undefined') {
        showLabel = true;
    }

    if (versionType === 'dataType') {
        if (!Array.isArray(versionedItems) || versionedItems.length === 0) {
            placeholder = 'Loading...';
        }
    }

    //<editor-fold desc="Handlers and Functions">
    /**
     * @param {string} documentId
     * @returns {boolean}
     */
    const isDocumentCacheOutdated = (documentId) => {
        if (Array.isArray(documentIdVersions)) {
            for (const temp of documentIdVersions) {
                if (documentId === temp.version.documentId) {
                    return false;
                }
            }
        }

        return true;
    };
    /**
     * @param {VersionedDocument} previousItem
     * @param {VersionedDocument} currentItem
     */
    const isNewSectionStarting = (previousItem, currentItem) => {
        return (
            getDisplayNameForVersionType(versionType, previousItem).substring(0, 1).toLowerCase() !==
            getDisplayNameForVersionType(versionType, currentItem).substring(0, 1).toLowerCase()
        );
    };

    /**
     * @param {Event} e
     */
    const handleOpen = (e) => {
        if (!isDisabled) {
            setIsOpen(true);
            setMenuAnchorEl(e?.currentTarget || null);
        }
    };

    /**
     * Closes the menu regardless of any conditions and resets the menu state.
     */
    const resetMenuState = () => {
        setIsOpen(false);
        setVersionFilterText('');
        setDocumentFilterText('');
    };

    /**
     * @param {Event} e
     */
    const handlePopoverClose = (e) => {
        handleEventPropagation(e);

        if (e?.currentTarget?.classList && e.currentTarget.classList.contains('MuiBackdrop-root')) {
            // Reset menu to previous selected version
            if (selectedVersion) {
                setCurrentMainItemSelected(selectedVersion);
                setCurrentMenuDisplayed(MENU_ITEM_VERSION_SELECT);
            }
            resetMenuState();
        }
    };

    /**
     * @param {Event} e
     */
    const handleEventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    /**
     * @returns {Promise<void>}
     */
    const loadDocumentVersions = async (documentId) => {
        if (versionType && documentId) {
            setLoadingVersions(true);
            getVersionsByDocumentId(versionType, documentId)
                .then((data) => {
                    if (data !== false) {
                        setDocumentIdVersions(data);
                    }
                })
                .catch(() => {
                    showSnackBarErrorNotification(`There was an error loading versions for ${versionType}`);
                })
                .finally(() => {
                    setLoadingVersions(false);
                });
        }
    };

    /**
     * Used to catch any errors that the handle change may generate
     * @param versionedDocument
     * @returns {Promise<void>}
     */
    const runChangeHandler = async (versionedDocument = {}) => {
        try {
            await handleChange(versionedDocument);
        } catch (error) {
            logError('Received Error: ', error);
            showSnackBarErrorNotification(`There was an error trying to set the document: ${versionType}`);
        }
    };

    /**
     * Clears selection
     */
    const handleResetSelection = async (e) => {
        handleEventPropagation(e);

        setCurrentMainItemSelected(null);
        setSelectedVersion(null);
        setCurrentMenuDisplayed(MENU_ITEM_SELECT);
    };

    /**
     * @param {VersionedDocument} versionObject
     * @returns {string}
     */
    const getVersionDisplayName = (versionObject) => {
        return `${versionObject.version.name} v${versionObject.version.id}`;
    };
    //</editor-fold>

    //<editor-fold desc="Render Functions">
    /**
     * @param placeholder
     * @param filterSetter
     * @param currentFilterValue
     * @returns {JSX.Element}
     */
    const renderSearchInputForSection = (placeholder, filterSetter, currentFilterValue) => {
        const iconStyle = {
            fill: '#777',
            position: 'absolute',
            right: '20px',
            top: 0,
            bottom: 0,
            margin: 'auto 0',
        };

        return (
            <div
                style={{
                    position: 'relative',
                    fontSize: '14px',
                    padding: '10px',
                    width: '100%',
                }}
            >
                <input
                    type={'text'}
                    onChange={(e) => {
                        filterSetter(e.target.value);
                    }}
                    placeholder={placeholder}
                    autoFocus
                    style={{
                        width: '100%',
                        height: '36px',
                        borderRadius: '15px',
                        border: '1px solid lightgray',
                        padding: '10px',
                    }}
                    value={currentFilterValue}
                />
                {currentFilterValue === '' && <SearchIcon style={iconStyle} />}
                {currentFilterValue !== '' && (
                    <CancelIcon
                        style={{ ...iconStyle, cursor: 'pointer' }}
                        onClick={(e) => {
                            handleEventPropagation(e);

                            filterSetter('');
                        }}
                    />
                )}
            </div>
        );
    };

    /**
     * Conditionally renders the menu items based on version type.
     * @param item
     * @param index
     * @returns {JSX.Element}
     */
    const renderMenuItem = (item, index) => {
        switch (versionType) {
            case 'mapping':
            case 'crosswalk':
            case 'comparison':
            case 'integration':
                return (
                    <MenuItem
                        key={index}
                        value={item.version.documentId}
                        onClick={() => {
                            setCurrentMainItemSelected(item);
                        }}
                        selected={currentMainItemSelected?.version?.documentId === item.version.documentId}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div>{getDisplayNameForVersionType(versionType, item)}</div>
                            <div>
                                <ArrowForwardIosIcon style={{ height: '12px', width: '12px', fill: '#777' }} />
                            </div>
                        </div>
                    </MenuItem>
                );
            case 'dataType':
                return (
                    <MenuItem
                        key={index}
                        value={item.version.documentId}
                        onClick={() => {
                            setCurrentMainItemSelected(item);
                        }}
                        selected={currentMainItemSelected?.version?.documentId === item.version.documentId}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                minWidth: '700px',
                                maxWidth: '700px',
                            }}
                        >
                            <div style={{ width: '95%' }}>
                                <div>{getDisplayNameForVersionType(versionType, item)}</div>
                                <div
                                    style={{
                                        color: '#999',
                                        fontStyle: 'italic',
                                        fontSize: '13px',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    title={item?.description ? item.description : ''}
                                >
                                    {item?.category || ''}
                                    {item?.description ? ` - ${item.description}` : ''}
                                </div>
                            </div>
                            <div>
                                <ArrowForwardIosIcon style={{ height: '12px', width: '12px', fill: '#777' }} />
                            </div>
                        </div>
                    </MenuItem>
                );
        }
    };

    /**
     * Handles rendering main menu
     * @returns {*[]}
     */
    const renderMainMenuItems = () => {
        const outputList = [];
        const displayedDocumentIds = [];
        let currentIndex = 1;
        let previousItem = {};

        if (filteredVersionedItems.length > 0) {
            for (const filteredVersionItem of filteredVersionedItems) {
                if (!displayedDocumentIds.includes(filteredVersionItem.version.documentId)) {
                    displayedDocumentIds.push(filteredVersionItem.version.documentId);
                    if (isNewSectionStarting(previousItem, filteredVersionItem)) {
                        outputList.push(
                            <ListSubheader key={currentIndex++} sx={versionStateHeaderStyle}>
                                {getDisplayNameForVersionType(versionType, filteredVersionItem)
                                    .substring(0, 1)
                                    .toUpperCase()}
                            </ListSubheader>,
                        );
                    }
                    outputList.push(renderMenuItem(filteredVersionItem, currentIndex++));

                    previousItem = filteredVersionItem;
                }
            }
        }

        if (filteredVersionedItems.length === 0) {
            outputList.push(
                <ListSubheader key={currentIndex++} sx={versionStateHeaderStyle}>
                    No results found
                </ListSubheader>,
            );
        }

        return outputList;
    };

    /**
     * Renders the version menu item for the selected item.
     * @param item
     * @param index
     * @returns {JSX.Element}
     */
    const renderVersionMenuItem = (item, index) => {
        switch (versionType) {
            case 'mapping':
            case 'crosswalk':
            case 'dataType':
            case 'comparison':
            case 'integration':
                return (
                    <MenuItem
                        key={index}
                        value={item.version.id}
                        onClick={async () => {
                            setCurrentMainItemSelected(item);
                            setSelectedVersion(item);
                            await runChangeHandler(item);
                        }}
                        selected={selectedVersion && selectedVersion?.version?.id === item.version.id}
                    >
                        {getVersionDisplayName(item)}
                    </MenuItem>
                );
        }
    };

    /**
     * @returns {*[]}
     */
    const renderVersionedMenuItems = () => {
        let filteredDocumentIdVersions;
        if (typeof additionalFilterFunction === 'function') {
            filteredDocumentIdVersions = additionalFilterFunction(documentIdVersions);
        } else {
            filteredDocumentIdVersions = documentIdVersions;
        }

        const draftVersions = filteredDocumentIdVersions
            .filter((item) => item.version.publishedId === null)
            .filter((item) => item.version.name.toLowerCase().includes(versionFilterText) || versionFilterText === '')
            .sort(sortFunctionToUse);
        const publishedVersions = filteredDocumentIdVersions
            .filter((item) => item.version.publishedId !== null)
            .filter((item) => item.version.name.toLowerCase().includes(versionFilterText) || versionFilterText === '')
            .sort(sortFunctionToUse);
        const hasResults = draftVersions.length > 0 || publishedVersions.length > 0;
        const outputItems = [];
        let currentIndex = 1;

        if (hasResults) {
            if (draftVersions.length > 0) {
                outputItems.push(
                    <ListSubheader key={currentIndex++} sx={versionStateHeaderStyle}>
                        Drafts
                    </ListSubheader>,
                );
                for (const draftVersion of draftVersions) {
                    outputItems.push(renderVersionMenuItem(draftVersion, currentIndex++));
                }
            }
            if (publishedVersions.length > 0) {
                outputItems.push(
                    <ListSubheader key={currentIndex++} sx={versionStateHeaderStyle}>
                        Published
                    </ListSubheader>,
                );
                for (const publishedVersion of publishedVersions) {
                    outputItems.push(renderVersionMenuItem(publishedVersion, currentIndex++));
                }
            }
        } else {
            outputItems.push(
                <ListSubheader key={currentIndex++} sx={versionStateHeaderStyle}>
                    No results found
                </ListSubheader>,
            );
        }

        return outputItems;
    };

    /**
     * Renders the main clickable area with selected information
     * @returns {JSX.Element}
     */
    const renderSelectClickableArea = () => {
        return (
            <div
                className={'versionedDocumentSelector-selectedElement'}
                style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: !getDisplayNameForVersionType(versionType, selectedVersion) ? 'none' : 'block',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '85%',
                        marginBottom: hideVersionInformationInSelect ? '0' : '5px',
                        fontWeight: hideVersionInformationInSelect ? '' : 'bold',
                    }}
                >
                    {getDisplayNameForVersionType(versionType, selectedVersion)}
                </div>
                <div
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '85%',
                    }}
                >
                    {!hideVersionInformationInSelect && (
                        <>
                            {selectedVersion
                                ? `${getVersionDisplayName(selectedVersion)} - ${dayjs(
                                      selectedVersion?.updatedAt,
                                  ).format(DATE_FORMAT)}`
                                : placeholder || 'Please select a versioned item'}
                        </>
                    )}
                </div>
                {!isOpen && selectedVersion && allowClear && !isDisabled && (
                    <CancelIcon
                        style={{ marginRight: '25px', cursor: 'pointer' }}
                        title={'Clear entry'}
                        onClick={handleResetSelection}
                    />
                )}
                {!isDisabled && <>{isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}</>}
            </div>
        );
    };

    /**
     * Renders the first page of results of the menu
     * @returns {JSX.Element}
     */
    const renderMainDocumentSelect = () => {
        return (
            <div style={menuStyle}>
                {renderSearchInputForSection(`Filter ${versionType}...`, setDocumentFilterText, documentFilterText)}
                <div
                    style={{
                        background: '#999',
                        width: '75%',
                        height: '1px',
                        margin: 'auto',
                    }}
                ></div>
                <MenuList
                    onClick={(e) => {
                        handleEventPropagation(e);

                        setCurrentMenuDisplayed(MENU_ITEM_VERSION_SELECT);
                    }}
                    defaultValue={currentMainItemSelected?.version?.documentId || ''}
                >
                    {renderMainMenuItems()}
                </MenuList>
            </div>
        );
    };

    /**
     * Renders the menu for a selected version.
     * @returns {JSX.Element}
     */
    const renderDocumentVersionMenu = () => {
        return (
            <div style={menuStyle}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setCurrentMenuDisplayed(MENU_ITEM_SELECT);
                    }}
                >
                    <div style={{ padding: '10px 0 0 10px' }}>
                        <ArrowBackIcon style={{ fill: '#777' }} />
                    </div>
                    <div style={{ padding: '10px 10px 0 10px' }}>
                        {currentMainItemSelected?.name || `${versionType} Version`}
                        <div style={{ fontStyle: 'italic', color: 'gray' }}>Select a version</div>
                    </div>
                </div>
                {renderSearchInputForSection('Filter versions...', setVersionFilterText, versionFilterText)}
                <div
                    style={{
                        background: '#999',
                        width: '75%',
                        height: '1px',
                        margin: 'auto',
                    }}
                ></div>
                {!isLoadingVersions ? (
                    <MenuList
                        onClick={(e) => {
                            handleEventPropagation(e);

                            resetMenuState();
                        }}
                        defaultValue={selectedVersion?.version?.id || ''}
                    >
                        {renderVersionedMenuItems()}
                    </MenuList>
                ) : (
                    <div style={{ display: 'flex', padding: '10px' }} title={'Loading versioned items...'}>
                        <div style={{ margin: 'auto' }}>
                            <CircularProgress style={{ width: '24px', height: '24px' }} />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    //</editor-fold>

    //<editor-fold desc="useEffect Section">
    /**
     * Loads versions for selected value.
     */
    useEffect(() => {
        let foundItem = false;

        if (selectedItem) {
            for (const item of versionedItems) {
                if (item?._id === selectedItem) {
                    setCurrentMainItemSelected(item);
                    setSelectedVersion(item);
                    setCurrentMenuDisplayed(MENU_ITEM_VERSION_SELECT);
                    foundItem = true;
                    if (isDocumentCacheOutdated(item.version.documentId)) {
                        loadDocumentVersions(item.version.documentId);
                    }

                    break;
                }
            }
        }

        if (!foundItem) {
            setCurrentMainItemSelected(null);
            setSelectedVersion(null);
            setCurrentMenuDisplayed(MENU_ITEM_SELECT);
        }

        return () => {
            setCurrentMainItemSelected(null);
            setSelectedVersion(null);
            setCurrentMenuDisplayed(MENU_ITEM_SELECT);
        };
    }, [selectedItem, versionedItems]);

    /**
     * Resets the anchor for the Popover component when close is fired
     */
    useEffect(() => {
        if (!isOpen) {
            setMenuAnchorEl(null);
        }
    }, [isOpen]);

    /**
     * Resets the root item selected or loads it's versions when a new one is set
     */
    useEffect(() => {
        if (currentMainItemSelected) {
            if (isDocumentCacheOutdated(currentMainItemSelected?.version?.documentId)) {
                loadDocumentVersions(currentMainItemSelected.version.documentId);
            }
        }
    }, [currentMainItemSelected]);

    /**
     * Load a versioned item by ID if we do not have an actual value passed
     */
    useEffect(() => {
        if (!selectedItem && selectedItemId) {
            let foundVersionedItem = null;
            if (Array.isArray(versionedItems)) {
                foundVersionedItem = versionedItems.find((item) => item._id === selectedItemId);
            }

            if (!foundVersionedItem) {
                getVersionedDocumentById(versionType, selectedItemId)
                    .then((response) => {
                        if (Array.isArray(response)) {
                            setSelectedVersion(response[0]);
                        }
                    })
                    .catch(() => {
                        showSnackBarErrorNotification('There was an error loading selected item by ID');
                    });
            } else {
                setSelectedVersion(foundVersionedItem);
            }
        }
    }, [selectedItemId]);
    //</editor-fold>

    return (
        <>
            {(showLabel === true || typeof showLabel === 'string') && !overrideLabel && (
                <InputLabel
                    style={{
                        textTransform: 'capitalize',
                        fontSize: '14px',
                        marginRight: '15px',
                        color: '#000',
                        ...(styleOverrides?.labelStyles || {}),
                    }}
                >
                    {showLabel === true ? versionType : showLabel}
                    {isRequired ? <span style={styles.required}>*</span> : ''}
                </InputLabel>
            )}
            {typeof overrideLabel !== 'undefined' && overrideLabel}
            <div
                className={`versionedDocumentSelector-root ${
                    isRequired && hasError ? 'versionedDocumentSelector-error' : ''
                } ${isDisabled ? 'versionedDocumentSelector-disabled' : ''}`}
                onClick={handleOpen}
                style={{ ...(styleOverrides?.containerStyles || {}) }}
            >
                {renderSelectClickableArea()}
                <Popover
                    open={isOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    anchorEl={menuAnchorEl}
                    onClose={handlePopoverClose}
                >
                    <div style={{ position: 'relative', minWidth: '400px', color: '#333' }}>
                        {currentMenuDisplayed === MENU_ITEM_SELECT && renderMainDocumentSelect()}
                        {currentMenuDisplayed === MENU_ITEM_VERSION_SELECT && renderDocumentVersionMenu()}
                    </div>
                </Popover>
            </div>
        </>
    );
};

VersionedDocumentSelector.propTypes = {
    versionType: PropTypes.string.isRequired,
    selectedItem: PropTypes.string,
    versionedItems: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    hasError: PropTypes.bool,
    isDisabled: PropTypes.bool,
    showLabel: PropTypes.any,
    placeholder: PropTypes.string,
    sortFunction: PropTypes.func,
    styleOverrides: PropTypes.object,
    allowClear: PropTypes.bool,
    overrideLabel: PropTypes.node,
    additionalFilterFunction: PropTypes.func,
    selectedItemId: PropTypes.string,
    hideVersionInformationInSelect: PropTypes.bool,
};

export default memo(VersionedDocumentSelector);
