import { authorizedAxiosInstance } from '../axiosInstances';
import _ from 'lodash';

import * as constants from './constants';
import HttpStatusCodes from '../classes/HttpStatusCodes';
import { sendGetRequest, processResponse } from './dataRequestUtils';
import { getCurrentUserId, getCurrentSelectedOrganizationId } from './loginUtils';

const BASE_URL = process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST,
    ignoreErrorCodes = [404];

/**
 *
 * @param {String} organizationId
 * @returns {Promise<{}>}
 * @throws {Error}
 */
export const getVendorInstancesById = async (organizationId) => {
    return authorizedAxiosInstance
        .get(`${BASE_URL}/user/${getCurrentUserId()}/organization/${organizationId}/vendor/instances`)
        .then((res) => {
            if (res.status === HttpStatusCodes.SUCCESS) {
                return res.data;
            } else {
                throw new Error('An invalid response was received attempting to load organization vendor instances.');
            }
        })
        .catch(() => {
            throw new Error('An error was detected attempting to load organization vendor instances.');
        });
};

/**
 *
 * @param {Array} organizationVendors
 * @param {String} vendorId
 * @returns {[] | false} array with needed parameter data or false if no vendor instance fields is found
 */
export const getMergedAvailableInstanceFields = (
    selectedVendorInstace,
    selectedVendorInstaceInterfaceType,
    selectedAuthenticationMethod,
) => {
    const organizationVendorInstanceInterfaceType =
        selectedVendorInstace &&
        selectedVendorInstace.interfaces &&
        selectedVendorInstace.interfaces.find(
            (instanceElement) => instanceElement.interfaceType === selectedVendorInstaceInterfaceType,
        );
    const organizationVendorInstanceAuthenticationMethod =
        (selectedAuthenticationMethod && selectedAuthenticationMethod.authenticationAvailableInstanceFields) || [];

    if (organizationVendorInstanceInterfaceType || organizationVendorInstanceAuthenticationMethod) {
        const vendorFields = organizationVendorInstanceInterfaceType.availableInstanceFields;
        const mergedAvailableInstanceFields = {};

        for (const obj of vendorFields) {
            mergedAvailableInstanceFields[obj.name] = obj;
        }

        for (const obj of organizationVendorInstanceAuthenticationMethod) {
            mergedAvailableInstanceFields[obj.name] = {
                ...(mergedAvailableInstanceFields[obj.name] || {}),
                ...obj,
            };
        }

        const fields = Object.keys(mergedAvailableInstanceFields).map((key) => mergedAvailableInstanceFields[key]);

        return _.cloneDeep(fields);
    }
    return false;
};
/**
 *
 * @param {Array} organizationVendors
 * @param {String} vendorId
 * @returns {[] | false} array with needed parameter data or false if no vendor instance fields is found
 */
export const getOrganizationVendorInstanceFields = (organizationVendors, vendorId) => {
    const organizationVendorInstance = organizationVendors.find((instanceElement) => instanceElement._id === vendorId);

    if (organizationVendorInstance) {
        const vendorFields = organizationVendorInstance.availableInstanceFields.map((field) => {
            return field.name;
        });

        return _.cloneDeep(vendorFields);
    }
    return false;
};

/**
 *
 * @param {Array} organizationVendors
 * @param {String} vendorId
 * @returns {[] | false} array with needed parameter data or false if no vendor instance fields is found
 */
export const getMergedAvailableInstanceField = (
    selectedVendorInstaceInterfaceType,
    authenticationMethod,
    organizationVendorInstance,
) => {
    const interfaceTypes = organizationVendorInstance.interfaces.find(
        (instanceElement) => instanceElement.interfaceType === selectedVendorInstaceInterfaceType,
    );
    if (interfaceTypes) {
        const organizationVendorInstanceAvailableInstanceFields =
            (interfaceTypes && interfaceTypes.availableInstanceFields) || [];
        const organizationVendorInstanceAuthenticationMethods =
            interfaceTypes &&
            interfaceTypes.authenticationMethods.find(
                (instanceElement) => instanceElement.authenticationMethod === authenticationMethod,
            );
        if (organizationVendorInstanceAuthenticationMethods) {
            const organizationVendorInstanceAuthenticationMethodAvailableInstanceFields =
                organizationVendorInstanceAuthenticationMethods.authenticationAvailableInstanceFields || [];

            const mergedAvailableInstanceFields = {};

            for (const obj of organizationVendorInstanceAvailableInstanceFields) {
                mergedAvailableInstanceFields[obj.name] = obj;
            }

            for (const obj of organizationVendorInstanceAuthenticationMethodAvailableInstanceFields) {
                mergedAvailableInstanceFields[obj.name] = {
                    ...(mergedAvailableInstanceFields[obj.name] || {}),
                    ...obj,
                };
            }

            const fields = Object.keys(mergedAvailableInstanceFields).map((key) => mergedAvailableInstanceFields[key]);

            return _.cloneDeep(fields);
        }

        return _.cloneDeep(organizationVendorInstanceAvailableInstanceFields);
    }

    return false;
};

/**
 *
 * @param {String} organization
 * @param {Array} organizationVendorInstances
 * @param {Array} vendorInstances
 * @returns {[] | false}
 */
export const getOrganizationVendorInstancesForDisplay = (
    organization,
    organizationVendorInstances,
    vendorInstances,
) => {
    const outputVendorInstanceList = [];

    for (const vendorInstance of vendorInstances) {
        const organizationVendorInstance = organizationVendorInstances.find(
            (instanceElement) => instanceElement._id === vendorInstance.vendorId,
        );

        if (organizationVendorInstance) {
            const outputVendorInstance = {
                orgId: organization,
                name: vendorInstance.name,
                vendorId: vendorInstance.vendorId,
                interfaceType: vendorInstance.interfaceType,
                _id: vendorInstance._id,
                availableInstanceFields: getMergedAvailableInstanceField(
                    vendorInstance.interfaceType,
                    vendorInstance.authenticationMethod,
                    organizationVendorInstance,
                ),
                authenticationMethod: vendorInstance.authenticationMethod, //TODO refactor to use database driven value
            };

            outputVendorInstance.availableInstanceFields.forEach((field) => {
                outputVendorInstance[field.name] = vendorInstance[field.name];
            });

            outputVendorInstanceList.push(outputVendorInstance);
        }
    }

    if (outputVendorInstanceList) {
        return _.cloneDeep(outputVendorInstanceList);
    }

    return false;
};

/**
 *
 * @returns {{availableInstanceFields: *[], clientId: string, organizationPhoneNumber: string, authToken: string, vendorId: string, orgId: (string|Organizations.ClientConfiguration|Organizations.apiVersion|*), interfaceType: string, password: string, authenticationMethod: string, name: string, clientSecret: string, flowSID: string, accountSID: string, username: string}}
 */
export const getDefaultOrganizationVendorInstance = () => {
    return {
        orgId: getCurrentSelectedOrganizationId(), // will need to change since user organizations could be multiple
        vendorId: '',
        interfaceType: '',
        authenticationMethod: '',
        name: '',
        clientId: '',
        clientSecret: '',
        username: '',
        password: '',
        // TODO this needs to change with refactor of instance fields
        availableInstanceFields: [
            {
                name: 'clientId',
                text: 'Client ID',
                type: 'string',
                required: true,
            },
            {
                name: 'clientSecret',
                text: 'Client Secret',
                type: 'string',
                required: true,
            },
        ],
        accountSID: '',
        authToken: '',
        organizationPhoneNumber: '',
        flowSID: '',
    };
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<{}>}
 * @throws {Error}
 */
export const getOrganizationById = async (organizationId) => {
    return authorizedAxiosInstance
        .get(`${BASE_URL}/organization/${organizationId}`)
        .then((res) => {
            if (res.status === HttpStatusCodes.SUCCESS) {
                return res.data;
            } else {
                throw new Error('An invalid response was received attempting to load organization vendor instances.');
            }
        })
        .catch(() => {
            throw new Error('An error was detected attempting to load organization vendor instances.');
        });
};

/**
 *
 * @returns {Promise<{}>}
 */
export const getUserOrganizationData = async () => {
    return authorizedAxiosInstance
        .get(`${BASE_URL}/user/${getCurrentUserId()}/organizations`)
        .then((res) => {
            if (res.status === HttpStatusCodes.SUCCESS) {
                return res.data;
            } else {
                throw new Error('An invalid response was received attempting to load organizations.');
            }
        })
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @returns {[{availableInstanceFields: *[], clientId: string, organizationPhoneNumber: string, authToken: string, vendorId: string, orgId: (string|Organizations.ClientConfiguration|Organizations.apiVersion|*), interfaceType: string, password: string, authenticationMethod: string, name: string, clientSecret: string, flowSID: string, accountSID: string, username: string}]}
 */
export const getDefaultOrganizationVendorInstanceList = () => {
    return [getDefaultOrganizationVendorInstance()];
};

/**
 * Get all app instances
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getOrganizationAppInstances = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${getCurrentSelectedOrganizationId()}/app/instances`,
        data: {},
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch app instances from server',
        }),
    });

/**
 * Check if organization ID is intely
 * @param {String} organizationId
 * @return {Bool}
 */
export const isIntelyOrganizationId = (organizationId) => constants.INTELY_ORGANIZATION_ID === organizationId;

/**
 * @typedef IntegrationScriptParameter
 * @property {string} _id
 * @property {string} name
 * @property {string} displayName
 * @property {string?} description
 * @property {'string'|'encrypted-string'|'date'|'date-time'|'number'|'boolean'|'object'|'xml'|'crosswalkId'} dataType
 * @property {boolean} isArray
 */

/**
 * @typedef IntegrationScript
 * @property {string} _id
 * @property {string} organizationId
 * @property {string} name
 * @property {string?} description
 * @property {IntegrationScriptParameter[]?} parameters
 * @property {string} outputDataTypeId
 * @property {string} scriptLanguage
 * @property {boolean} isEnabled
 * @property {boolean} isPublic
 * @property {string} createdAt
 * @property {string} createdBy
 * @property {string} updatedAt
 * @property {string} updatedBy
 * @property {string} deletedAt
 * @property {string} deletedBy
 */

/**
 * @param {string} organizationId
 * @param {func} successCallback
 * @param {func?} errorCallback
 * @param {func?} finallyCallback
 * @returns {Promise<*>}
 */
export const getAvailableExecutionScriptsFor = async (
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
) => {
    return sendGetRequest({
        url: `scripts`,
        data: {},
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch list of scripts from server',
        }),
    });
};

/**
 * @param {string} organizationId
 * @param {string} scriptId
 * @param {func} successCallback
 * @param {func?} errorCallback
 * @param {func?} finallyCallback
 * @returns {Promise<*>}
 */
export const getExecutionScriptFor = async (
    organizationId,
    scriptId,
    successCallback,
    errorCallback,
    finallyCallback,
) => {
    return sendGetRequest({
        url: `scripts/${scriptId}`,
        data: {},
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch script from server',
        }),
    });
};

/**
 * @param {string} organizationId
 * @param {func} successCallback
 * @param {func} errorCallback
 * @param {func} finallyCallback
 * @returns {Promise<*>}
 */
export const getAppInstanceStatsForOrganization = async (
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
) => {
    return sendGetRequest({
        url: `organization/${organizationId}/app/instances/count`,
        data: {},
        successCallback,
        errorCallback,
        finallyCallback,
    });
};
