const labelStyle = {
    fontSize: '14px',
    marginRight: '15px',
    color: '#000',
};

export default {
    mainStyles: {
        padding: '25px 0px 5px 0px',
        width: '100%',
        borderBottom: '1px solid #4f4f4f1f',
    },
    stepActionStyle: { marginRight: '-10px' },
    requestBodyField: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '0px 10px',
    },
    crosswalkLabel: {
        ...labelStyle,
        textTransform: 'capitalize',
    },
    fieldGrid: {
        flex: 0.8,
        flexGrow: 1,
    },
    required: { color: 'red', marginLeft: '4px' },
    selectFieldContainer: { display: 'flex', flexDirection: 'row', marginBottom: '5px' },
    inputFieldWrapper: { display: 'flex', justifyItems: 'stretch', alignItems: 'center', padding: '5px 0' },
    textField: { margin: 'auto' },
    removeInputButton: {
        height: '3em',
        background: '#3a70d4',
        borderRadius: '10px',
        marginLeft: '5px',
        width: '50px',
        border: '1px solid #3a70d4',
    },
    removeButtonText: { fontSize: '2em', cursor: 'pointer' },
};
